import React from "react";
import {Column, ColumnHeading, LinkList,} from "./tailwind.styles";
import {LegalLinks} from "./FooterLink";

export const FooterLegalColumn = () => {
    return (
        <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
                {LegalLinks}
            </LinkList>
        </Column>
    )
}
