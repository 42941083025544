import {IconContainer, Link, LinkListItem, LinkWithIcon} from "./tailwind.styles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import GroupsIcon from '@mui/icons-material/Groups';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PolicyIcon from '@mui/icons-material/Policy';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ArticleIcon from '@mui/icons-material/Article';
import {COSMIC_EMAIL, COSMIC_PHONE_NUMBER_ALT, COSMIC_PHONE_NUMBER_TEL} from "../../app/constants";
import React, {ReactElement} from "react";
import {FooterIconLinkProps} from "./FooterIconLink.types";
import {Route} from "../Routes/Route";
import {useNavigateTo} from "../../hooks/useNavigateTo";
import GdprIcon from "../../icons/GdprIcon/GdprIcon";

export default function FooterLink({link, text, newTab = false, icon, route}: FooterIconLinkProps) {
    const {navigateTo} = useNavigateTo();

    return (
        <LinkListItem>
            {icon != null ?
                <LinkWithIcon>
                    <IconContainer>{icon}</IconContainer>
                    {newTab ?
                        <Link href={link} target="_blank" rel="noopener,noreferrer" onClick={navigateTo(route)}>{text}</Link>
                        :
                        <Link href={link} onClick={navigateTo(route)}>{text}</Link>
                    }
                </LinkWithIcon>
                :
                <>
                    {newTab ?
                        <Link href={link} target="_blank" rel="noopener,noreferrer" onClick={navigateTo(route)}>{text}</Link>
                        :
                        <Link href={link} onClick={navigateTo(route)}>{text}</Link>
                    }
                </>
            }
        </LinkListItem>
    )
}

export const CompanyLoginLink = () =>
    <FooterLink link={Route.Login} text="Log In" icon={<LoginIcon/>} route={Route.Login} />

export const CompanyAboutLink = () =>
    <FooterLink link={Route.About} text="About" icon={<InfoIcon/>} route={Route.About} />

export const CompanyBlogLink = () =>
    <FooterLink link={Route.Blog} text="Blog" icon={<RssFeedIcon/>} route={Route.Blog} />

export const CompanyFaqsLink = () =>
    <FooterLink link={Route.Faqs} text="FAQS" icon={<QuestionMarkIcon/>} route={Route.Faqs} />

export const CompanyTeamLink = () =>
    <FooterLink link={Route.MeetOurTeam} text="Meet Our Team" icon={<GroupsIcon/>} route={Route.MeetOurTeam} />

export const CompanyLinks: Array<ReactElement> = [
    <CompanyLoginLink key="company-login-link"/>,
    <CompanyAboutLink key="company-about-link"/>,
    <CompanyBlogLink key="company-blog-link"/>,
    <CompanyFaqsLink key="company-faqs-link"/>,
    <CompanyTeamLink key="company-team-link"/>
]

export const LegalGdprLink = () => <FooterLink link={Route.Gdpr} icon={<GdprIcon color="primary"/>} text="GDPR" newTab/>

export const LegalPrivacyPolicyLink = () =>
    <FooterLink link={Route.PrivacyPolicy} text="Privacy Policy" icon={<PolicyIcon/>} route={Route.PrivacyPolicy}/>

export const LegalTermsAndConditionsLink = () =>
    <FooterLink link={Route.TermsAndConditions} text="Terms of Service" icon={<ArticleIcon/>} route={Route.TermsAndConditions}/>

export const LegalDisclaimerLink = () =>
    <FooterLink link={Route.Disclaimer} text="Disclaimer" icon={<AnnouncementIcon/>} route={Route.Disclaimer}/>

export const LegalLinks: Array<ReactElement> = [
    <LegalGdprLink key="legal-gdpr-link"/>,
    <LegalPrivacyPolicyLink key="legal-privacy-policy-link"/>,
    <LegalTermsAndConditionsLink key="legal-terms-and-conditions-link"/>,
    <LegalDisclaimerLink key="legal-disclaimer-link"/>
]

export const ContactPhoneLink = () =>
    <FooterLink link={COSMIC_PHONE_NUMBER_TEL} text={COSMIC_PHONE_NUMBER_ALT} icon={<PhoneIcon/>} />

export const ContactEmailLink = () =>
    <FooterLink link={`mailto:${COSMIC_EMAIL}`} text={COSMIC_EMAIL} icon={<EmailIcon/>} />

export const ContactUsLink = () =>
    <FooterLink link={Route.ContactUs} text="Contact Us" icon={<ContactPageIcon/>} route={Route.ContactUs} />

export const ContactSupportLink = () =>
    <FooterLink link={Route.Support} text="Support" icon={<ContactSupportIcon/>} route={Route.Support}/>

export const ContactLinks: Array<ReactElement> = [
    <ContactPhoneLink key="contact-phone-link"/>,
    <ContactEmailLink key="contact-email-link"/>,
    <ContactUsLink key="contact-us-link"/>,
    <ContactSupportLink key="contact-support-link"/>
]


