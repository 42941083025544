import React from "react";
import {
    CompanyAddress,
    CompanyColumn,
    IconContainer, LinkWithIcon,
    LogoContainer,
    LogoImg,
    LogoText,
    Map,
    MapContainer
} from "./tailwind.styles";
import {
    COSMIC_ADDRESS,
    COSMIC_COMPANY_NAME_ALT,
    COSMIC_LOGO_ALPHA,
    GOOGLE_MAPS_LINK_URL,
    GOOGLE_MAPS_URL
} from "../../app/constants";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const FooterAddressColumn = () => {
    return (
        <CompanyColumn id="--cbb-footer-company-column">
            <LogoContainer id="--cbb-footer-logo-container">
                <LogoImg id="--cbb-footer-logo-img" src={COSMIC_LOGO_ALPHA}/>
                <LogoText id="--cbb-footer-logo-text">{COSMIC_COMPANY_NAME_ALT}</LogoText>
            </LogoContainer>
            <LinkWithIcon>
                <IconContainer>
                    <LocationOnIcon/>
                </IconContainer>
                <CompanyAddress
                    id="--cbb-footer-company-address"
                    href={GOOGLE_MAPS_LINK_URL}
                    target="_blank"
                    rel="noopener,noreferrer"
                >
                    {COSMIC_ADDRESS}
                </CompanyAddress>
            </LinkWithIcon>
            <MapContainer>
                <Map
                    id="--cosmic-footer-google-map"
                    url={GOOGLE_MAPS_URL}
                    loading="lazy"
                    display="block"
                />
            </MapContainer>
        </CompanyColumn>
    )
}
