import {Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import React from "react";
import {BusinessHourProps} from "./BusinessHour.types";
import Typography from "@mui/material/Typography";
import Moment from "react-moment";
import {toUpperCaseFilter} from "../../app/utils";
import {isOpen} from "../../app/models";

export default function BusinessHour({day, hours}: BusinessHourProps) {
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={5}>
                <Typography paragraph variant="body2" color={useTheme().palette.neutral.main}>
                    {day}
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography paragraph variant="body2" color={useTheme().palette.neutral.main}>
                    {isOpen(hours) ?
                        <>
                            <Moment
                                interval={0}
                                date={hours.open}
                                format="LT"
                                filter={toUpperCaseFilter}
                            />
                            -
                            <Moment
                                interval={0}
                                date={hours.close}
                                format="LT"
                                filter={toUpperCaseFilter}
                            />
                        </> :
                        "Closed"
                    }
                </Typography>
            </Grid>
        </Grid>
    )
}
