import {HardHatIcon} from "@patternfly/react-icons";
import "@patternfly/react-core/dist/styles/base.css";
import EmptyStatePaper from "../components/EmptyStatePaper";

export default function UnderConstruction() {
    return (
        <EmptyStatePaper
            title="Under Construction"
            icon={HardHatIcon}
            body="The page you are looking is under construction and isn't available yet. Please check back soon!"
        />
    )
}