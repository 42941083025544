import {Stack} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ReactElement} from "react";
import {AmenityProps} from "./Amenity.types";
import {
    CarIcon,
    WifiIcon,
    CreditCardIcon,
    AccessibleIconIcon,
    PawIcon,
    PuzzlePieceIcon
} from "@patternfly/react-icons";

export default function Amenity({amenity, icon}: AmenityProps) {
    return (
        <Stack justifyContent="center" alignItems="center" direction="row" spacing={2}>
            {icon}
            <Typography paragraph variant="body2" color={useTheme().palette.neutral.main}>
                {amenity}
            </Typography>
        </Stack>
    )
}

export const Parking = () =>
    <Amenity amenity="Parking" icon={<CarIcon size="lg" color={useTheme().amenity.icon}/>}/>

export const Wifi = () =>
    <Amenity amenity="Wi-Fi" icon={<WifiIcon size="lg" color={useTheme().amenity.icon}/>}/>

export const CreditCardsAccepted = () =>
    <Amenity amenity="Credit Cards" icon={<CreditCardIcon size="lg" color={useTheme().amenity.icon}/>}/>

export const Accessibility = () =>
    <Amenity amenity="Accessibility" icon={<AccessibleIconIcon size="lg" color={useTheme().amenity.icon}/>}/>

export const PetsAllowed = () =>
    <Amenity amenity="Pets Allowed" icon={<PawIcon size="lg" color={useTheme().amenity.icon}/>}/>

export const ChildFriendly = () =>
    <Amenity amenity="Child-Friendly" icon={<PuzzlePieceIcon size="lg" color={useTheme().amenity.icon}/>}/>

export const AmenityIcons: Array<ReactElement> = [
    <Parking/>,
    <Wifi/>,
    <CreditCardsAccepted/>,
    <Accessibility/>,
    <PetsAllowed/>,
    <ChildFriendly/>
]
