import * as React from "react";
import TopAppBar from "./components/TopAppBar";
import Toolbar from "@mui/material/Toolbar";
import RootContainer from "./components/styled/RootContainer/RootContainer";
import Footer from "./components/Footer/Footer";
import CookieConsentPopup from "./components/CookieConsentPopup/CookieConsentPopup";
import Routes from "./components/Routes";
import ScrollToTopFab from "./components/ScrollToTopFab";
import Main from "./components/Main";
import FullContainer from "./components/FullContainer";
import Home from "./pages/Home";

export default function App() {
    return (
        <RootContainer>
            {/*<CookieConsentPopup/>*/}
            <TopAppBar/>
            <Main>
                <Toolbar id="back-to-top-anchor"/>
                <FullContainer>
                    <Routes/>
                </FullContainer>
            </Main>
            <Footer/>
            <ScrollToTopFab/>
        </RootContainer>
    );
}