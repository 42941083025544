import React from "react";
import BusinessHours from "../BusinessHours/BusinessHours";
import {BusinessHoursColumn, ColumnHeading,} from "./tailwind.styles";
import tw from "twin.macro";

const BusinessHoursList = tw.ul`mt-4 text-sm font-medium`;

export const FooterBusinessHoursColumn = () => {
    return (
        <BusinessHoursColumn id="--cbb-footer-business-hours-column">
            <ColumnHeading id="--cbb-footer-business-hours-column-heading">Business Hours</ColumnHeading>
            <BusinessHoursList>
                <BusinessHours/>
            </BusinessHoursList>
        </BusinessHoursColumn>
    )
}
