export function isEmptyString(s: string | undefined): boolean {
    return (s ?? "") === "";
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function openInNewTab(url: string): void {
    if (url != null) {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer")
        if (newWindow) newWindow.opener = null;
    }
}

export const toUpperCaseFilter = (date : string) => {
    return date.toUpperCase();
};