export enum Route {
    Home = "/",
    Services = "/services",
    Faqs = "/faqs",
    About = "/about",
    MeetOurTeam = "/meet-our-team",
    ContactUs = "/contact",
    Gallery = "/gallery",
    Policies = "/policies",
    SignUp = "/signup",
    Login = "/login",
    Blog = "/blog",
    PrivacyPolicy = "/privacy-policy",
    TermsAndConditions = "/terms-and-conditions",
    Disclaimer = "/disclaimer",
    // Profile = "/profile/:uuid"
    Profile = "/profile/",
    NotFound = "*",
    Support = "/support",
    Gdpr = "https://gdpr.eu/"
}