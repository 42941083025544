import {Paper} from "@mui/material";
import {styled} from "@mui/material/styles";

const FullPaper = styled(Paper) ({
    padding: 10,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    minWidth: "100%",
    minHeight: "100%"
})

export default FullPaper;