import React from "react";
import {InstagramSocialLink} from "../CompanySocialLinks";
import {Divider} from "@mui/material";
import Copyright from "../Copyright/Copyright";
import {COSMIC_COMPANY_NAME_ALT, COSMIC_WEBSITE} from "../../app/constants";
import MadeWithLove from "../MadeWithLove/MadeWithLove";
import Amenities from "../Amenities/Amenities";
import {Container, FiveColumns, FooterContent} from "../tailwind/Layouts";
import {AmenitiesRow, CopyrightAndCompanyInfoRow} from "./tailwind.styles";
import {FooterAddressColumn} from "./FooterAddressColumn";
import {FooterBusinessHoursColumn} from "./FooterBusinessHoursColumn";
import {FooterContactColumn} from "./FooterContactColumn";
import {FooterLegalColumn} from "./FooterLegalColumn";
import {FooterCompanyColumn} from "./FooterCompanyColumn";

export default function Footer() {
    return (
        <Container id="--cbb-footer">
            <FooterContent id="--cbb-footer-content">
                <Divider id="--cbb-footer-top-divider" sx={{mt: 4, mx: 4}} />
                <FiveColumns id="--cbb-footer-grid-columns">
                    <FooterAddressColumn/>
                    <FooterBusinessHoursColumn/>
                    <FooterCompanyColumn/>
                    <FooterLegalColumn/>
                    <FooterContactColumn/>
                </FiveColumns>
                {/*<SocialLinksContainer>*/}
                {/*    <InstagramSocialLink/>*/}
                {/*</SocialLinksContainer>*/}
                <AmenitiesRow>
                    <Amenities/>
                </AmenitiesRow>
                <Divider sx={{mt: 2, mx: 4}} />
                <CopyrightAndCompanyInfoRow>
                    <Copyright url={COSMIC_WEBSITE} company={COSMIC_COMPANY_NAME_ALT} sx={{mt: 1}}/>
                    <MadeWithLove sx={{mt: 1}}/>
                </CopyrightAndCompanyInfoRow>
            </FooterContent>
        </Container>
    );
}
