import React, {useState} from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import {Paper} from "@mui/material";
import { SectionHeading, Subheading as SubheadingBase } from "../components/tailwind/Headings";
import { Container, ContentWithPaddingXl } from "../components/tailwind/Layouts";
import styled from "@emotion/styled";
import {faqs} from "../app/faqs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Faq} from "../app/models";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
// `;
// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
// `;

export default function Faqs() {
    const subheading = "FAQS"
    const heading = "You have Questions ?"
    const description = "And we have got answers to all of them. Lorem ipsum dolor sit amet, consectetur adipiscing elit."

    const [question, setQuestion] = useState<number | null>(null);

    const toggleQuestion = (index: number) => {
        if (question === index) setQuestion(null);
        else setQuestion(index);
    }

    return (
        <Paper sx={{ m: 2, p: 2 }}>
            <Container>
                <ContentWithPaddingXl>
                    <Column>
                        <HeaderContent>
                            <Subheading>{subheading}</Subheading>
                            <Heading>{heading}</Heading>
                            <Description>{description}</Description>
                        </HeaderContent>
                        <FAQSContainer>
                            {faqs.map((faq: Faq, index: number) => (
                                <FAQ
                                    key={index}
                                    onClick={() => {
                                        toggleQuestion(index);
                                    }}
                                    className="group"
                                >
                                    <Question>
                                        <QuestionText>{faq.question}</QuestionText>
                                        <QuestionToggleIcon
                                            variants={{
                                                collapsed: { rotate: 0 },
                                                open: { rotate: -180 }
                                            }}
                                            initial="collapsed"
                                            animate={question === index ? "open" : "collapsed"}
                                            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                                        >
                                            <ExpandMoreIcon />
                                        </QuestionToggleIcon>
                                    </Question>
                                    <Answer
                                        variants={{
                                            open: { opacity: 1, height: "auto", marginTop: "16px" },
                                            collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                                        }}
                                        initial="collapsed"
                                        animate={question === index ? "open" : "collapsed"}
                                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    >
                                        {faq.answer}
                                    </Answer>
                                </FAQ>
                            ))}
                        </FAQSContainer>
                    </Column>
                </ContentWithPaddingXl>
            </Container>
        </Paper>
    );
}