import * as React from "react";
import {EmptyStatePaperProps} from "./EmptyStatePaper.types";
import Grid from "@mui/material/Grid";
import {EmptyState, EmptyStateBody, EmptyStateIcon, EmptyStateVariant, Title} from "@patternfly/react-core";
import {useTheme} from "@mui/material/styles";
import ZoomFab from "../ZoomFab";
import {useNavigate} from "react-router-dom";
import {Route} from "../Routes/Route";
import HomeIcon from "@mui/icons-material/Home";
import FullPaper from "../styled/FullPaper";
import "@patternfly/react-core/dist/styles/base.css";

export default function EmptyStatePaper({title, icon, body}: EmptyStatePaperProps) {
    return (
        <FullPaper style={{padding: 50}}>
            <Grid container direction="column" alignItems="center" justifyContent="center" sx={{minHeight: "100%"}}>
                <Grid item>
                    <EmptyState variant={EmptyStateVariant.xl} style={{padding: 5, margin: 5, textAlign: "center"}}>
                        <EmptyStateIcon color={useTheme().palette.neutral.main} icon={icon} style={{display: "block", margin: "auto"}} />
                        <Title headingLevel="h5" size="4xl" style={{marginTop: 20}}>
                            {title}
                        </Title>

                        {(body != null) &&
                            <EmptyStateBody style={{marginTop: 20, marginBottom: 20}}>
                                {body}
                            </EmptyStateBody>
                        }
                        <ZoomFab
                            size="large"
                            label="Return to Home Page"
                            onClick={() => useNavigate()(Route.Home)}
                            icon={<HomeIcon sx={{mr: 1}}/>}
                            sx={{
                                position: "relative",
                                bottom: 0,
                                right: 0
                            }}
                        />
                    </EmptyState>
                </Grid>
            </Grid>
        </FullPaper>
    );
}
