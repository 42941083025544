import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import {SvgIconProps} from "@mui/material";

export default function GdprIcon({...props}: SvgIconProps) {
    return (
        <SvgIcon
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt"
            height="512.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
            {...props}
        >
            <g transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)' stroke='none'>
                <path d='M2510 5113 c-2206 -907 -2125 -871 -2151 -952 -19 -56 3 -494 42
-846 108 -975 382 -1776 813 -2378 216 -302 509 -581 781 -743 117 -70 329
-162 413 -180 128 -27 267 -12 384 39 197 87 614 440 867 732 430 497 750
1122 931 1818 111 425 165 818 177 1282 6 227 5 243 -14 286 -11 25 -36 57
-54 71 -19 14 -495 215 -1059 448 -1001 413 -1082 443 -1130 423z m534 -557
c1143 -472 1369 -566 1374 -571 9 -8 -16 -441 -33 -590 -136 -1164 -636 -2153
-1408 -2789 -166 -136 -309 -232 -368 -246 -108 -26 -179 -2 -368 124 -165
111 -292 216 -446 370 -409 410 -697 908 -884 1528 -131 437 -210 985 -211
1464 l0 141 232 97 c128 53 541 224 918 380 377 156 694 284 704 285 10 1 231
-86 490 -193z' />
                <path d='M1621 4068 c-219 -84 -252 -406 -55 -536 84 -56 220 -66 332 -24 l47
18 0 159 0 160 -132 3 -133 3 0 -76 0 -75 50 0 50 0 0 -36 0 -37 -46 6 c-110
12 -165 123 -115 233 32 71 127 94 230 55 l49 -18 20 71 c12 39 18 74 14 77
-4 4 -29 14 -55 23 -69 23 -188 20 -256 -6z' />
                <path d='M2118 4083 l-38 -4 0 -294 0 -293 80 -7 c257 -23 420 94 424 304 1
83 -20 145 -70 200 -65 72 -124 94 -254 97 -58 1 -122 0 -142 -3z m233 -158
c44 -23 69 -71 69 -130 0 -92 -47 -150 -132 -161 l-48 -7 0 157 0 156 40 0
c23 0 54 -7 71 -15z' />
                <path d='M2738 4083 l-38 -4 0 -295 0 -294 80 0 80 0 0 95 0 94 38 6 c67 12
103 27 139 58 104 91 93 242 -21 312 -44 27 -57 30 -145 31 -53 1 -113 0 -133
-3z m196 -159 c9 -8 16 -21 16 -29 0 -22 -34 -55 -60 -57 -23 -3 -25 0 -28 50
-3 52 -3 52 27 52 16 0 37 -7 45 -16z' />
                <path d='M3270 4082 l-35 -7 -3 -292 -2 -293 80 0 79 0 3 110 3 109 24 -12
c14 -7 28 -23 32 -37 4 -14 17 -58 29 -97 l23 -73 88 0 c49 0 89 2 89 4 0 3
-9 20 -19 38 -11 18 -29 71 -41 118 -12 46 -26 92 -31 102 -8 14 -4 26 13 51
17 23 24 49 26 97 3 55 0 70 -20 100 -13 19 -44 46 -68 60 -38 20 -60 25 -140
26 -52 2 -111 -1 -130 -4z m184 -148 c10 -4 16 -18 16 -35 0 -22 -6 -32 -26
-39 -47 -18 -54 -14 -54 35 0 41 2 45 24 45 13 0 31 -3 40 -6z' />
                <path d='M2400 3304 c-214 -56 -398 -229 -466 -438 -23 -68 -28 -105 -32 -231
l-4 -150 -40 -43 c-22 -24 -52 -69 -66 -100 l-27 -57 0 -430 0 -430 27 -57
c37 -80 107 -152 187 -191 l66 -32 515 0 c514 0 515 0 564 23 105 48 184 137
218 244 16 54 18 98 18 442 0 321 -3 391 -16 437 -18 61 -45 108 -90 159 l-31
35 -6 145 c-3 87 -12 169 -22 205 -62 224 -248 408 -471 465 -92 24 -242 26
-324 4z m264 -350 c65 -22 148 -100 176 -166 14 -32 24 -82 28 -130 l5 -78
-313 0 -313 0 5 83 c9 142 82 247 204 291 53 20 150 19 208 0z m336 -744 c6
-9 10 -159 10 -356 0 -277 -3 -344 -14 -353 -9 -8 -135 -10 -447 -9 l-434 3
-3 355 c-2 328 -1 356 15 368 14 10 109 12 441 10 363 -3 424 -5 432 -18z' />
                <path d='M2483 2100 c-84 -51 -104 -178 -37 -241 23 -21 24 -29 24 -141 l0
-118 90 0 90 0 0 119 c0 95 3 121 16 132 46 38 59 139 24 197 -42 69 -139 94
-207 52z' />
            </g>
        </SvgIcon>
    )
}