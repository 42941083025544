import {RouteIcon} from "@patternfly/react-icons";
import "@patternfly/react-core/dist/styles/base.css";
import EmptyStatePaper from "../components/EmptyStatePaper";

export default function NotFound() {
    return (
        <EmptyStatePaper
            title="404: Page not found"
            icon={RouteIcon}
            body="The page you are looking for might have been removed, had its name changed or is temporarily unavailable."
        />
    )
}