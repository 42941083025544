import {Faq} from "./models";

export const faqs: Array<Faq> = [
    {
        question: "What are eyelash extensions?",
        answer:
            "Eyelash extensions are used to enhance the length, curliness, fullness, and thickness of natural eyelashes. They are made of synthetic fibers and are individually applied to each natural lash using medical grade adhesive."
    },
    {
        question: "Are eyelash extensions safe?",
        answer:
            "Yes, when applied by a licensed professional and following proper after care instructions."
    },
    {
        question: "How long will eyelash extensions last?",
        answer:
            "Typically, lash extensions last anywhere from 2 to 4 weeks depending on the individual’s natural growth cycle and aftercare. Anything after four weeks is considered to be a full set, due to natural growth.\n" +
            "\n"
    },
    {
        question: "Can I wear mascara?",
        answer:
            "Nope! The purpose of eyelash extensions is designed to give the fullness that mascara typically gives. Wearing mascara with volume lash extensions will close the volume fans and ruin your extensions."
    },
    {
        question: "Can I get my eyelash extensions wet?",
        answer:
            "Yes, but only after 24 hours after each service."
    }
]