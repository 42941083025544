import {createContext, PropsWithChildren, useContext} from "react";
import {development, production} from "../app/config";
import {Config} from "../app/models";
import {DEV, PRODUCTION} from "../app/constants";

export type ConfigContextType = {
    config: Config,
    debug: boolean
}

const ConfigContext = createContext<ConfigContextType>({
    config: production,
    debug: false
})

export function useConfig() {
    return useContext(ConfigContext);
}

export function ConfigProvider({children}: PropsWithChildren<Record<never, never>>) {
    const environment = process.env.REACT_APP_CONFIG ?? PRODUCTION;
    const config: Config = environment === DEV ? development : production;
    const debug = Boolean(process.env.REACT_APP_DEBUG);

    return(
        <ConfigContext.Provider value={{config, debug}}>
            {children}
        </ConfigContext.Provider>
    )
}