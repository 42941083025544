import tw from "twin.macro";

export const Container = tw.div`relative w-full`;

export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

export const ContentWithPadding = tw.div`max-w-screen-xl mx-auto py-4 lg:py-6`;

export const ContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-4 lg:py-6`;

export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;

export const Content2Xl= tw.div`max-w-screen-2xl mx-auto`;

export const FooterContent = tw.div`mx-auto w-11/12 pt-16 pb-8`

export const FiveColumns = tw.div`mx-auto py-12 lg:py-12 flex flex-wrap justify-around`;