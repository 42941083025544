import {MouseEvent, PropsWithChildren, useEffect} from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Zoom from "@mui/material/Zoom";
import Box from "@mui/material/Box";
import {useLocation} from "react-router-dom";
import {ScrollToTopProps} from "./ScrollToTop.types";

export default function ScrollToTop({window, children}: PropsWithChildren<ScrollToTopProps>) {
    const {pathname} = useLocation();

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    useEffect(() => {
        handleClick()
    }, [pathname]);

    const handleClick = (event?: MouseEvent<HTMLDivElement>) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const anchor = ((event?.target as HTMLDivElement)?.ownerDocument || document)
            .querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Zoom>
    );
}