import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import NavigationMenu from "./NavigationMenu";
import { center } from '../app/styles';
import SettingsMenu from "../menus/SettingsMenu";
import BusinessAvatar from "./BusinessAvatar";

export default function TopAppBar() {
    return (
        <AppBar position="absolute" sx={{pb: 0.5}}>
            <Toolbar>
                <BusinessAvatar/>
                {/*<Box sx={{flexGrow: 1, ...center}}>*/}
                {/*    <NavigationMenu/>*/}
                {/*</Box>*/}
                {/*<Box>*/}
                {/*    <SettingsMenu/>*/}
                {/*</Box>*/}
            </Toolbar>
        </AppBar>
    );
}
