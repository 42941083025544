import {FabPropsVariantOverrides, Zoom} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {OverridableStringUnion} from "@mui/types";
import {useTheme} from "@mui/material/styles";
import {SxProps} from "@mui/system";
import _ from "lodash";
import {ZoomFabProps} from "./ZoomFab.types";

export default function ZoomFab(
    {
        label,
        color = "primary",
        icon = <AddIcon sx={{mr: 1}} />,
        onClick,
        sx,
        ...props
    }: ZoomFabProps
) {
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen
    }

    const fabStyle = {
        position: "fixed",
        bottom: 16,
        right: 16,
        mr: 1,
        mb: 1,
        ...sx // overrides any of these default style values.
    } as SxProps;

    const variant = !_.isEmpty(label) ? "extended" :
        ("circular" as OverridableStringUnion<"circular" | "extended", FabPropsVariantOverrides>)

    return (
        <Zoom
            key={color}
            in={true}
            timeout={transitionDuration}
            style={{
                transitionDelay: `${transitionDuration.enter}ms`
            }}
            unmountOnExit
        >
            <Fab
                {...props}
                variant={variant}
                sx={fabStyle}
                aria-label={label}
                color={color}
                onClick={onClick}
            >
                {icon}
                {label}
            </Fab>
        </Zoom>
    )
}
