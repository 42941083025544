import * as React from "react";
import {Route as NavRoute, Routes as NavRoutes} from "react-router-dom";
import {Route} from "./Route";
import Home from "../../pages/Home";
import Faqs from "../../pages/Faqs";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import TermsAndConditions from "../../pages/TermsAndConditions";
import NotFound from "../../pages/NotFound";
import UnderConstruction from "../../pages/UnderConstruction";

export default function Routes() {
    return (
        <NavRoutes>
            <NavRoute path={Route.Home} element={<Home/>}/>
            <NavRoute path={Route.Faqs} element={<Faqs/>}/>
            <NavRoute path={Route.PrivacyPolicy} element={<PrivacyPolicy/>}/>
            <NavRoute path={Route.TermsAndConditions} element={<TermsAndConditions/>}/>
            <NavRoute path={Route.NotFound} element={<NotFound/>}/>

            {/*  TODO all under construction.  */}
            <NavRoute path={Route.MeetOurTeam} element={<UnderConstruction/>}/>
            <NavRoute path={Route.About} element={<UnderConstruction/>}/>
            <NavRoute path={Route.Blog} element={<UnderConstruction/>}/>
            <NavRoute path={Route.Login} element={<UnderConstruction/>}/>
            <NavRoute path={Route.Services} element={<UnderConstruction/>}/>
            <NavRoute path={Route.ContactUs} element={<UnderConstruction/>}/>
            <NavRoute path={Route.Disclaimer} element={<UnderConstruction/>}/>
            <NavRoute path={Route.Gallery} element={<UnderConstruction/>}/>
            <NavRoute path={Route.Policies} element={<UnderConstruction/>}/>
            <NavRoute path={Route.Profile} element={<UnderConstruction/>}/>
            <NavRoute path={Route.SignUp} element={<UnderConstruction/>}/>
        </NavRoutes>
    );
}
