import Typography from "@mui/material/Typography";
import React from "react";
import {MadeWithLoveProps} from "./MadeWithLove.types";

export default function MadeWithLove(
    {
        country = "United States",
        align = "center",
        color = "textSecondary",
        variant="caption",
        ...props
    }: MadeWithLoveProps
) {
    return (
        <Typography paragraph variant={variant} align={align} color={color} {...props}>
            Made with ❤️ in the {country}
        </Typography>
    )
}