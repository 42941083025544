import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {AmenityIcons} from "../Amenity";

export default function Amenities() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                {AmenityIcons.map((icon, index) => (
                    <Grid key={index} item xs>
                        {icon}
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
