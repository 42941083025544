import Carousel from "../components/parallax/Carousel";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React from "react";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";

import {BOOKSY_URL} from "../app/constants";
import backgroundImage from "../assets/images/banner.png"

// const backgroundImage =
//     'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400';

const HomeRoot = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '80vh',
        minHeight: 500,
        maxHeight: 1300,
    },
}));

const Background = styled(Box)({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
});

export type HomeLayoutProps = {
    sxBackground: SxProps<Theme>
}

export function HomeLayout(
    props: React.HTMLAttributes<HTMLDivElement> & HomeLayoutProps,
) {
    const { sxBackground, children } = props;

    return (
        <HomeRoot>
            <Container
                sx={{
                    mt: 3,
                    mb: 14,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {children}
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: 'common.black',
                        opacity: 0.5,
                        zIndex: -1,
                    }}
                />
                <Background sx={sxBackground} />
                <Box
                    component="img"
                    src="https://mui.com/static/themes/onepirate/productHeroArrowDown.png"
                    height="16"
                    width="12"
                    alt="arrow down"
                    sx={{ position: 'absolute', bottom: 32 }}
                />
            </Container>
        </HomeRoot>
    );
}

export default function Home() {
    return (
        <HomeLayout
            sxBackground={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#7fc7d9', // Average color of the background image.
                backgroundPosition: 'center',
            }}
        >
            {/* Increase the network loading priority of the background image. */}
            <img
                style={{ display: 'none' }}
                src={backgroundImage}
                alt="increase priority"
            />
            {/*<Typography color="inherit" align="center" variant="h2">*/}
            {/*    Upgrade your Sundays*/}
            {/*</Typography>*/}
            <Typography
                color="inherit"
                align="center"
                variant="h5"
                sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
            >
                Click "Book Now" to schedule your appointment with us today!
            </Typography>
            <Button
                color="primary"
                variant="contained"
                size="large"
                component="a"
                href={BOOKSY_URL}
                sx={{ minWidth: 100 }}
            >
                Book Now
            </Button>
            {/*<Typography variant="body2" color="inherit" sx={{ mt: 2 }}>*/}
            {/*    Discover the experience*/}
            {/*</Typography>*/}
        </HomeLayout>

    // <Carousel/>
    );
}
