import {Moment} from "moment";

export type Key = string | number;

export type Config = {
    api_url: string
}

export type Name = {
    firstName: string,
    middleName?: string,
    lastName: string
}

export type ImageWithBackups = {
    url: {
        bmp?: string,
        jpg?: string,
        jp2?: string,
        jxr?: string,
        webp?: string
    }
}

export type ServiceTabInfo = {
    backgroundImage: string,
    title: ServiceType | "Popular",
    services: Array<ServiceFull>
}

export type ServiceFull = {
    imageSrc: ImageWithBackups,
    title: string,
    type: ServiceType,
    price: number | "Free" | "Contact Us" | "Varies",
    length: Duration,
    url: string,
    providers: Array<Profile>,
    description?: string,
    notes?: string
}

export type ServiceType = "Lash Extensions" | "Lash Lifts & Tints" | "Makeup" | "Waxing/Henna/Tints" | "Facial" | "Nails" | "Hair" | "Brow Lamination" | "Threading" | "Botox/Filler" | "Spray Tanning"

export type Profile = {
    id: number,
    name: Name,
    title: string,
    biography: string,
    services: Array<ServiceType>,
    picture: string,
    instagram?: string,
    linkedIn?: string,
    twitter?: string,
    facebook?: string
}

export type Footer = {
    title: string,
    description: Array<string>
}

export type Faq = {
    question: string,
    answer: string
}

export type WorkingHour = string[] | null;

export type WorkingHours = {
    0: WorkingHour,
    1: WorkingHour,
    2: WorkingHour,
    3: WorkingHour,
    4: WorkingHour,
    5: WorkingHour,
    6: WorkingHour
}

export type Holidays = {
    holidays: Array<string>
}

export type BusinessDay = {
    day: string,
    hours: OpeningHours | Closed
}

export type OpeningHours = {
    open: Moment,
    close: Moment
}

export type Closed = "Closed"

export function isOpen(hours: OpeningHours | Closed): hours is OpeningHours {
    return !isClosed(hours);
}

export function isClosed(hours: OpeningHours | Closed): hours is Closed {
    return (hours as Closed) === "Closed";
}

export type BusinessHours = Array<BusinessDay>