import React, {createContext, PropsWithChildren, useContext} from 'react';
import GoogleFontLoader, {GoogleFontLoaderProps} from 'react-google-font-loader';
import NoSsr from '@material-ui/core/NoSsr';

export const FontContext = createContext<GoogleFontLoaderProps['fonts']>([]);

export function useFonts() {
    return useContext(FontContext);
}

export function FontProvider({fonts, children}: PropsWithChildren<GoogleFontLoaderProps>) {
    return(
        <>
            <NoSsr>
                <GoogleFontLoader fonts={fonts} />
            </NoSsr>
            <FontContext.Provider value={fonts}>
                {children}
            </FontContext.Provider>
        </>
    )
}