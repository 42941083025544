import {createContext, PropsWithChildren, useContext, useMemo, useState} from "react";
import {getTheme, ThemeMode} from "../app/theme";
import {createTheme, PaletteMode, responsiveFontSizes} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {useFonts} from "./FontContext";
import _ from "lodash";

export type ThemeContextType = {
    toggleTheme: () => void;
}

const ToggleThemeContext = createContext<ThemeContextType>({
    toggleTheme: () => console.warn("No theme provider.")
})

export function useToggleTheme() {
    return useContext(ToggleThemeContext);
}

export function ToggleThemeProvider({children}: PropsWithChildren<Record<never, never>>) {
    const [mode, setMode] = useState<PaletteMode>(ThemeMode.Light);
    const fonts = useFonts();

    const fontNames = useMemo(() => _.map(fonts, "font"), [fonts])

    function toggleTheme() {
        setMode((prevMode: PaletteMode) => (prevMode === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light));
    }

    const theme = useMemo(() => responsiveFontSizes(createTheme(getTheme(mode, fontNames))), [mode])

    return(
        <ToggleThemeContext.Provider value={{toggleTheme}}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ToggleThemeContext.Provider>
    )
}