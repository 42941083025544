export const DEV = "dev";
export const PRODUCTION = "production";
export const APPOINTMENT_API = "appointmentApi";
export const BOOKSY_URL = "https://booksy.com/en-us/680707_cosmic-beauty-bar_eyebrows-lashes_22149_north-andover"
export const GOOGLE_MAPS_URL = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23458.81294951072!2d-71.118416!3d42.696275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e30667abebffff%3A0x7f0014e836e2f90f!2s575%20Chickering%20Rd%2C%20North%20Andover%2C%20MA%2001845!5e0!3m2!1sen!2sus!4v1645920120874!5m2!1sen!2sus"
export const GOOGLE_MAPS_LINK_URL = "https://goo.gl/maps/M6gbEUZ5Tb7qpvXg9";

// TODO should be loaded from database when database is setup.
export const COSMIC_COMPANY_NAME = "Cosmic Beauty Bar L.L.C.";
export const COSMIC_COMPANY_NAME_ALT = "Cosmic Beauty Bar LLC.";
export const COSMIC_WEBSITE = "https://cosmicbeautybar.com";
export const COSMIC_EMAIL = "thecosmicbeautybar@gmail.com";
export const COSMIC_ADDRESS = "575 Chickering Rd, North Andover, MA 01845";
export const COSMIC_LOGO_ALPHA = "cosmic-logo512-alpha.png";

// TODO will provide formatting functions later.
export const COSMIC_PHONE_NUMBER = "781.960.7719";
export const COSMIC_PHONE_NUMBER_ALT = "+1 (781) (960)-7719";
export const COSMIC_PHONE_NUMBER_TEL = "tel:7819607719";