import {createContext, PropsWithChildren, useContext} from "react";
import moment from 'moment';
import 'moment/min/locales';
import {BusinessHours, Holidays, WorkingHour, WorkingHours} from "../app/models";

const hours: WorkingHours = {
    0: null,
    1: ['10:00:00', '21:00:00'],
    2: ['10:00:00', '21:00:00'],
    3: ['10:00:00', '21:00:00'],
    4: ['10:00:00', '21:00:00'],
    5: ['10:00:00', '18:00:00'],
    6: ['10:00:00', '16:00:00'],
}

const holidays: Holidays = {
    holidays: [
        '*-12-25'
    ]
}

// https://www.npmjs.com/package/moment-business-time
export type LocaleContextType = {
    locale: string,
    workingHours: WorkingHours,
    holidays: Holidays,
    businessHours: BusinessHours
}

const LocaleContext = createContext<LocaleContextType>({
    locale: "en",
    workingHours: hours,
    holidays: holidays,
    businessHours: []
})

export function useLocale() {
    return useContext(LocaleContext);
}

export type LocaleProviderProps = {
    locale: string
}

export function LocaleProvider({children, locale}: PropsWithChildren<LocaleProviderProps>) {
    // Updates the global 'Moment' class to have the working hours and holidays set.
    moment.updateLocale(locale,{
        workinghours: hours,
        holidays: holidays.holidays
    });

    // TODO can update this depending on the user's locale  const locale = window.navigator.language;

    return(
        <LocaleContext.Provider
            value={{
                locale: locale,
                workingHours: moment().workingHours(),
                holidays: moment().holidays(),
                businessHours: moment().businessHours()
            }}
        >
            {children}
        </LocaleContext.Provider>
    )
}

declare module "moment" {
    interface Moment {
        workingHours(): WorkingHours;
        holidays(): Holidays;
        businessHours(): BusinessHours;
    }
}

// Declaring custom moment functions to access working hours and holidays.
moment.fn.workingHours = function (): WorkingHours {
    return getWorkingHours();
};

moment.fn.holidays = function (): Holidays {
    return getHolidays();
};

moment.fn.businessHours = function (): BusinessHours {
    return getBusinessHours();
};

export function getWorkingHours(): WorkingHours {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return moment.localeData()["_workinghours"] as WorkingHours;
}

export function getHolidays(): Holidays {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return moment.localeData()["_holidays"] as Holidays;
}

export function getBusinessHours(): BusinessHours {
    const workingHours = getWorkingHours();

    return [
        {
            day: moment().weekday(0).format("dddd"), // Sunday
            hours: formatWorkingHours(workingHours['0'])
        },
        {
            day: moment().weekday(1).format("dddd"), // Monday
            hours: formatWorkingHours(workingHours['1'])
        },
        {
            day: moment().weekday(2).format("dddd"), // Tuesday
            hours: formatWorkingHours(workingHours['2'])
        },
        {
            day: moment().weekday(3).format("dddd"), // Wednesday
            hours: formatWorkingHours(workingHours['3'])
        },
        {
            day: moment().weekday(4).format("dddd"), // Thursday
            hours: formatWorkingHours(workingHours['4'])
        },
        {
            day: moment().weekday(5).format("dddd"), // Friday
            hours: formatWorkingHours(workingHours['5'])
        },
        {
            day: moment().weekday(6).format("dddd"), // Saturday
            hours: formatWorkingHours(workingHours['6'])
        }
    ]
}

export function formatWorkingHours(hours: WorkingHour) {
    if (hours != null) {
        return {
            open: moment(hours[0], "HH:mm:ss"),
            close: moment(hours[1], "HH:mm:ss")
        }
    } else {
        // TODO translate
        return "Closed"
    }
}