import * as React from "react";
import { Box } from "@mui/material";
import {PropsWithChildren} from "react";
import {MainProps} from "./Main.types";

export default function Main({children, ...props}: PropsWithChildren<MainProps>) {
    return (
        <Box
            component="main"
            sx={{
                m: 0,
                p: 0,
                flexDirection: "column",
                display: "flex",
                flex: "1 1 auto",
                flexGrow: 1,
                overflow: "hidden"
            }}
            {...props}
        >
            {children}
        </Box>
    );
}
