import Avatar from "@mui/material/Avatar";
import {Link} from "react-router-dom";
import {Route} from "./Routes/Route";
import {AnimationWrapper} from "react-hover-animation";
import * as React from "react";

export default function BusinessAvatar() {
    return (
        <AnimationWrapper
            config={{
                transform: {
                    initial: 'scale(1)',
                    onHover: 'scale(1.1)'
                },
                opacity: {
                    initial: "1",
                    onHover: "1"
                }
            }}
            animationConfig={{
                duration: 200,
            }}
            reset={true}
        >
            <Avatar
                component={Link}
                to={Route.Home}
                alt="Cosmic Beauty Bar"
                src="cosmic-logo512-alpha.png"
                sx={{ width: 64, height: 64, cursor: "pointer" }}
            />
        </AnimationWrapper>
    )
}