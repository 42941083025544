import {styled} from "@mui/material/styles";
import {ThemeMode} from "../../../app/theme";
import {Container, ContainerProps} from "@mui/material";

type RootContainerProps = ContainerProps;

const RootContainer = styled((props: RootContainerProps) => {
    return <Container {...props} maxWidth={false} disableGutters={true} sx={{m: 0, p: 0}}/>
})(({ theme }) => ({
    height: "100%",
    width: "100%",
    minWidth: "100%",
    minHeight: "100%",
    // backgroundColor: theme.palette.mode == ThemeMode.Light ? "white" : theme.palette.grey[900],
}));

export default RootContainer;