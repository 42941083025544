import * as React from "react";
import ScrollToTop from "../ScrollToTop";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {ScrollToTopFabProps} from "./ScrollToTopFab.types";

export default function ScrollToTopFab(
    {
        color = "primary",
        size = "small",
        icon = <KeyboardArrowUpIcon />,
        ...props
    }: ScrollToTopFabProps
) {
    return (
        <ScrollToTop>
            <Fab color={color} size={size} aria-label="scroll back to top" {...props}>
                {icon}
            </Fab>
        </ScrollToTop>
    )
}




