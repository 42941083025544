import React from "react";
import {Column, ColumnHeading, LinkList,} from "./tailwind.styles";
import {CompanyLinks} from "./FooterLink";

export const FooterCompanyColumn = () => {
    return (
        <Column>
            <ColumnHeading>Company</ColumnHeading>
            <LinkList>
                {CompanyLinks}
            </LinkList>
        </Column>
    )
}
