import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {CopyrightProps} from "./Copyright.types";

export default function Copyright(
    {
        url,
        company,
        text = "Copyright © ",
        align = "center",
        color = "text.secondary",
        variant = "body2",
        ...props
    }: CopyrightProps
) {
    return (
        <Typography variant={variant} color={color} align={align} {...props}>
            {text}
            <Link color="inherit" href={url}>
                {company}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
