import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import BusinessHour from "../BusinessHour/BusinessHour";
import {useLocale} from "../../context/LocaleContext";

export default function BusinessHours() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                {useLocale().businessHours.map((day, index) => (
                    <BusinessHour key={index} day={day.day} hours={day.hours}/>
                ))}
            </Grid>
        </Box>
    )
}