import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import {ToggleThemeProvider} from "./context/ToggleThemeContext";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers'
import {FontProvider} from "./context/FontContext";
import {fonts} from "./app/fonts";
import './index.css';
import { StyledEngineProvider } from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import {ParallaxProvider} from "react-scroll-parallax";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {ConfigProvider} from "./context/ConfigContext";
import {LocaleProvider} from "./context/LocaleContext";

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider>
            <StyledEngineProvider>
                <CssBaseline/>
                <FontProvider fonts={fonts}>
                    <ToggleThemeProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <LocaleProvider locale="en">
                                <ParallaxProvider>
                                    <Router>
                                        <HelmetProvider>
                                            <Helmet>
                                                <meta charSet="utf-8" />
                                                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                                            </Helmet>
                                        </HelmetProvider>
                                        <App/>
                                    </Router>
                                </ParallaxProvider>
                            </LocaleProvider>
                        </LocalizationProvider>
                    </ToggleThemeProvider>
                </FontProvider>
            </StyledEngineProvider>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
