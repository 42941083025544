import React from "react";
import {Column, ColumnHeading, LinkList,} from "./tailwind.styles";
import {ContactLinks} from "./FooterLink";

export const FooterContactColumn = () => {
    return (
        <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
                {ContactLinks}
            </LinkList>
        </Column>
    )
}
