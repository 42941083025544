import {useNavigate} from "react-router-dom";
import {Route} from "../components/Routes/Route";
import React from "react";

export const useNavigateTo = () => {
    const navigate = useNavigate();

    const navigateTo = (to: Route | string | undefined) => (e: React.SyntheticEvent) => {
        if (to != null) {
            e.preventDefault();
            navigate(to);
        }
    };

    return { navigateTo };
}