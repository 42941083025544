import tw from "twin.macro";
import styled from "@emotion/styled";
import Iframe from "react-iframe";

export const Column = tw.div`md:w-1/6 px-4`;
export const BusinessHoursColumn = tw.div`w-full md:w-3/12 mb-10 md:mb-0 lg:mb-0 px-4`;
export const CompanyColumn = tw(Column)`text-center md:text-left w-full -mt-2 md:w-3/12 mb-10 md:mb-0 lg:mb-0 px-4`;

export const ColumnHeading = tw.h5`font-bold uppercase`;

export const LinkList = tw.ul`mt-4 text-sm font-medium`;
export const LinkListItem = tw.li`mt-3`;
export const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

export const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
export const LogoImg = tw.img`w-12`;
export const LogoText = tw.h5`ml-2 text-lg font-black text-primary-500`;

export const CompanyAddress = tw(Link)`mt-2 max-w-lg font-medium text-base mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

export const SocialLinksContainer = tw.div`mt-4`;
export const AmenitiesRow = tw.div`pb-2 m-2 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
export const CopyrightAndCompanyInfoRow = tw.div`pb-0 m-2 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`

export const LinkWithIcon = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row items-center`
export const IconContainer = styled.div`
  ${tw`mb-1 ml-1 mr-2 inline-block text-primary-500 text-center p-0 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 fill-primary-500`}
  }
`;

export const MapContainer = tw.div`mt-4 flex items-center justify-center md:justify-start`;
export const Map = styled(Iframe)`
    ${tw`mb-1 block text-center p-0 flex-shrink-0 container`}
`;