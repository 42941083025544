import { Config } from "./models";

export const local: Config = {
    api_url: "http://localhost:7070"
}

export const development: Config = {
    api_url: "http://localhost:7070"
}

export const production: Config = {
    api_url: "http://localhost:7070"
}

